<script setup lang="ts">
defineProps({
  hideActions: {
    type: Boolean,
    default: false,
  },
})

const { logout } = useAuth()
const { customer } = useCustomer()

const isOpen = ref(true)
</script>

<template>
  <div v-if="Object.keys(customer)?.length" class="pb-4 mb-4 shadow sm:rounded-lg px-4 py-2">
    <div class="flex items-center justify-between py-4">
      <h4 class="block text-xl font-bold text-primary-500">
        Dados pessoais
      </h4>

      <button
        v-if="!hideActions"
        aria-label="Botão para alternar entre aberto e fechado"
        type="button"
        @click="isOpen = !isOpen"
      >
        <Icon
          name="mdi:chevron-down"
          class="w-6 h-6 duration-300 text-primary-400"
          :class="isOpen ? 'rotate-180 duration-300' : ''"
        />
      </button>
    </div>

    <div class="relative">
      <div
        class="transition-all duration-500 ease-in-out overflow-hidden bg-white"
        :class="isOpen ? 'max-h-[100%] opacity-100' : 'max-h-0 opacity-0'"
      >
        <div class="flex items-center justify-between">
          <span class="block text-sm text-neutral-400 mb-2">
            {{ customer.name }} {{ customer.lastName }}
          </span>

          <span
            v-if="!hideActions"
            class="text-primary-500 font-semibold text-xs cursor-pointer"
            @click="logout"
          >
            Não sou eu
          </span>
        </div>
        <span class="block text-sm text-neutral-400 mb-2">
          {{ formatPhone(customer.phone) }}
        </span>
        <span class="block text-sm text-neutral-400">
          {{ customer.email }}
        </span>
      </div>
    </div>
  </div>
</template>
